import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import { ReactNode } from 'react';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { IconButton } from '@/components/Elements/IconButton';
import { ModalDialog } from '@/components/Elements/ModalDialog';
import { Form as FormComponent, TextAreaField } from '@/components/Form';

import { useProcessAdditionalCompensation } from '../api/processAdditionalCompensation';

type DeclineAdditionalCompensationProps = {
  id: number;
  fullName: string;
  amount: string;
  createdAt: string;
  requestType: string;
  description: string;
};

type InfoCardProps = {
  title: string;
  value: ReactNode;
};

const triggerButton = (
  <IconButton variant="danger">
    <XIcon />
  </IconButton>
);

const InfoCard = ({ title, value }: InfoCardProps) => (
  <div className="col-span-1 bg-ebony-clay-50 p-4 shadow-sm">
    <dt className="text-sm font-medium text-ebony-clay-900">{title}</dt>
    <dd className="mt-1 text-sm text-ebony-clay-600">{value}</dd>
  </div>
);

const schema = z.object({
  comment: z
    .string()
    .min(1, 'Comment should be more than 1 characters')
    .max(255, 'Comment should be less than 256 characters')
    .or(z.literal('')),
});

type FormSchema = z.infer<typeof schema>;

const FORM_ID = 'decline-time-off';

type FormProps = {
  workerFullName?: string;
  onSubmit: (values: FormSchema) => Promise<void>;
};

const Form = ({ onSubmit }: FormProps) => {
  return (
    <FormComponent<FormSchema, typeof schema>
      id={FORM_ID}
      onSubmit={onSubmit}
      schema={schema}
      options={{
        shouldUnregister: true,
      }}
    >
      {({ formState, register }) => {
        return (
          <div className="mt-2 space-y-6 border-gray-200 bg-ebony-clay-50 p-4 shadow-sm">
            <TextAreaField
              label="Comment (optional)"
              error={formState.errors['comment']}
              registration={register('comment')}
            />
          </div>
        );
      }}
    </FormComponent>
  );
};

export const DeclineAdditionalCompensation = ({
  id,
  fullName,
  amount,
  createdAt,
  description,
  requestType,
}: DeclineAdditionalCompensationProps) => {
  const processAdditionalCompensationRequest = useProcessAdditionalCompensation();

  const handleConfirmationClick = async ({ comment }: FormSchema) => {
    await processAdditionalCompensationRequest.mutateAsync({
      id,
      comment: comment || undefined,
      status: 'rejected',
    });
  };

  return (
    <ModalDialog
      icon={<ExclamationIcon className="h-6 w-6 text-froly-600" />}
      confirmButton={
        <Button
          isLoading={processAdditionalCompensationRequest.isLoading}
          disabled={
            processAdditionalCompensationRequest.isLoading ||
            processAdditionalCompensationRequest.isSuccess
          }
          form={FORM_ID}
          variant="danger"
          type="submit"
        >
          Decline
        </Button>
      }
      isDone={processAdditionalCompensationRequest.isSuccess}
      title="Decline additional compensation request?"
      triggerButton={triggerButton}
    >
      <dl className="grid gap-2 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <InfoCard title="Worker" value={fullName} />
        </div>
        <div className="sm:col-span-1">
          <InfoCard title="Amount" value={amount} />
        </div>
        <div className="sm:col-span-1">
          <InfoCard title="Date" value={createdAt} />
        </div>
        <div className="sm:col-span-2">
          <InfoCard title="Type" value={<span className="capitalize">{requestType}</span>} />
        </div>
        <div className="sm:col-span-2">
          <InfoCard title="Description" value={description} />
        </div>
      </dl>
      <Form onSubmit={handleConfirmationClick} />
    </ModalDialog>
  );
};
