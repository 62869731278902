import * as React from 'react';

import logo from '@/assets/via-logo-black.png';
import { Link } from '@/components/Elements';
import { Head } from '@/components/Head';

type LayoutProps = {
  children: React.ReactNode;
  title?: string;
};

export const Layout = ({ children, title }: LayoutProps) => (
  <>
    <Head title={title} />
    <div className="flex h-auto flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md lg:mt-12">
        <Link to="/">
          <img className="mx-auto h-12 w-auto" src={logo} alt="VIA" />
        </Link>
        {title && (
          <h2 className="mt-6 text-center text-xl leading-9 text-gray-900 sm:mx-auto sm:w-full sm:max-w-md">
            {title}
          </h2>
        )}
      </div>
      <main>{children}</main>
      <div className="m-auto pt-4 text-sm">
        <span className="text-gray-400">Need to create and account? </span>
        <Link to="/sign-up" className="underline">
          Sign up.
        </Link>
      </div>
    </div>
  </>
);
