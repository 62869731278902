import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';

type Option = {
  label: string;
  value: string;
};

const options: Option[] = [
  {
    label: 'Local currencies',
    value: 'local',
  },
  {
    label: 'USD',
    value: 'usd',
  },
];

export const SelectPaycycleCurrency = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValue = searchParams.get('filter[currencyType]') || undefined;

  return (
    <select
      className={clsx(
        'block w-full appearance-none',
        'rounded-3xl border border-gray-300',
        'px-8 py-2 capitalize shadow-sm',
        'focus:border-dodger-blue-600 focus:ring-dodger-blue-600',
        'truncate sm:text-sm'
      )}
      translate="no"
      onChange={(event) => setSearchParams({ 'filter[currencyType]': event.currentTarget.value })}
      defaultValue={defaultValue}
    >
      {options.map(({ label, value }) => (
        <option key={label} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};
