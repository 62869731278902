import { DocumentsAttachments } from '../components';

export const WorkerDocuments = () => {
  return (
    <div className="sm:col-span-3">
      <dt className="text-sm font-medium text-ebony-clay-500">Attachments</dt>
      <dd className="mt-1 text-sm text-ebony-clay-900">
        <DocumentsAttachments />
      </dd>
    </div>
  );
};
