import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { API_URL } from '@/config';
import { sentryCaptureException } from '@/lib/sentry';
import { useNotificationStore } from '@/stores/notifications';

export const axios = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

const authRequestInterceptor = (config: AxiosRequestConfig) => {
  config.headers = config.headers ?? {};
  config.headers.Accept = 'application/json';
  return config;
};

const handleResponseError = (error: any) => {
  const message = error.response?.data?.message || error.message || 'Something went wrong.';

  if (isAxiosError(error) && message !== 'Unauthenticated.') {
    useNotificationStore.getState().addNotification({
      type: 'error',
      title: message,
    });
    return Promise.reject();
  }
  sentryCaptureException(error);
};

const handleResponseData = (response: AxiosResponse<any, any>) => response.data;

export const isAxiosError = (payload: any): payload is AxiosError<unknown, any> =>
  Axios.isAxiosError(payload);

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(handleResponseData, handleResponseError);
