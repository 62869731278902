import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TPaginatedQuery } from '@/types';

import { TTimeOff } from '../types';

export const getTimeOffRequests = (
  page?: string,
  sort?: string,
  type?: string,
  createdBetween?: string
): Promise<TPaginatedQuery<TTimeOff[]>> =>
  axios.get(`${API_BASE_PATH}/workers/time-off`, {
    params: {
      sort,
      page,
      'filter[createdBetween]': createdBetween,
      'filter[type]': type,
      'filter[status]': 'submitted',
    },
  });

type QueryFnType = typeof getTimeOffRequests;

type UseTimeOffRequestsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useTimeOffRequests = ({ config }: UseTimeOffRequestsOptions = {}) => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || undefined;
  const sort = searchParams.get('sort') || undefined;
  const type = searchParams.get('filter[type]') || undefined;
  const createdBetween = searchParams.get('filter[createdBetween]') || undefined;

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['time-off-to-review', page, sort, type, createdBetween],
    queryFn: () => getTimeOffRequests(page, sort, type, createdBetween),
  });
};
