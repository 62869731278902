import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { TWorker } from '@/features/workers';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { TPaginatedQuery } from '@/types';

type AddTimeOffDTO = {
  type: string;
  startDate: string;
  endDate?: string;
  comment?: string;
} & Payee;

type Payee = {
  workerId: number;
};

type UseAddTimeOffOptions = {
  config?: MutationConfig<typeof addTimeOff>;
};

const addTimeOff = ({ workerId, ...formData }: AddTimeOffDTO): Promise<void> => {
  return axios.post(`${API_BASE_PATH}/workers/${workerId}/time-off`, formData);
};

export const useAddTimeOff = ({ config }: UseAddTimeOffOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('workers');

      const previousWorkers = queryClient.getQueryData<TPaginatedQuery<TWorker[]>>('workers');

      return { previousWorkers };
    },
    onError: (_, __, context: any) => {
      if (context?.previousWorkers) {
        queryClient.setQueryData('workers', context.previousWorkers);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('workers');
      addNotification({
        type: 'success',
        title: 'Success',
      });
    },
    ...config,
    mutationFn: addTimeOff,
  });
};
