import { SectionHeading } from '@/components/Layout/SectionHeading';

import { AdditionalPaymentsTable } from '../components/AdditionalCompensationsTable/AdditionalPaymentsTable';

export const ToReview = () => {
  return (
    <>
      <SectionHeading title="It looks like you have some additional payment requests… 👀" />
      <AdditionalPaymentsTable />
    </>
  );
};
