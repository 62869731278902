import { ArchiveIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import * as React from 'react';
import { ReactElement } from 'react';

import { Spinner } from '../Spinner';

import { SortComponent } from './SortComponent';

type TableColumn<Entry> = {
  title: string;
  field: keyof Entry;
  Cell?({ entry }: { entry: Entry }): ReactElement | null;
  isFullWidth?: boolean;
  isSortable?: boolean;
  isNarrowColumn?: boolean;
};

export type TableProps<Entry> = {
  data: Entry[];
  columns: TableColumn<Entry>[];
  downloadXLS?: React.ReactElement;
  pagination: React.ReactElement;
  emptyState?: string;
  isFetching: boolean;
};

export const Table = <Entry extends { id: number }>({
  data,
  columns,
  downloadXLS,
  pagination,
  emptyState,
  isFetching,
}: TableProps<Entry>) => {
  if (!data.length || !data) {
    return (
      <div className="flex h-80 flex-col items-center justify-center border border-ebony-clay-100 bg-white text-ebony-clay-500 sm:rounded-3xl">
        <ArchiveIcon className="h-16 w-16" />
        <h4>{emptyState || 'No entries found'}</h4>
      </div>
    );
  }
  return (
    <>
      <div className="overflow-scroll pb-2">
        <table className="min-w-full divide-y divide-ebony-clay-100">
          <thead className="bg-ebony-clay-50">
            <tr>
              {columns.map((column, index) => (
                <th
                  key={column.title + index}
                  scope="col"
                  className={clsx(
                    column.isFullWidth && 'w-full',
                    'whitespace-nowrap px-6 py-4 text-left text-xs font-medium uppercase tracking-wider text-ebony-clay-500',
                    column.isNarrowColumn && 'px-2'
                  )}
                >
                  <div className="flex items-center">
                    {column.title}
                    <div className="flex w-4 items-center justify-center">
                      <span className="ml-2 flex-none rounded bg-ebony-clay-200 text-ebony-clay-900 group-hover:bg-ebony-clay-300">
                        {isFetching && column.isSortable !== false ? (
                          <Spinner size="xs" variant="primary" className="my-1 mx-0.5" />
                        ) : (
                          <SortComponent
                            columnField={column.field as string}
                            isSortable={column.isSortable}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-ebony-clay-100 bg-white">
            {data.map((entry, entryIndex) => (
              <tr key={entry.id || entryIndex}>
                {columns.map(({ Cell, field, title, isNarrowColumn }, columnIndex) => (
                  <td
                    key={title + columnIndex}
                    className={clsx(
                      'whitespace-nowrap px-6 py-4 text-ebony-clay-500 sm:text-base md:text-sm',
                      isNarrowColumn && 'px-2'
                    )}
                  >
                    {Cell ? <Cell entry={entry} /> : <>{entry[field]}</>}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-between">
        <div>{downloadXLS}</div>
        <div>{pagination}</div>
      </div>
    </>
  );
};
