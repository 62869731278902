import { ExclamationIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';

import { Button, Spinner } from '@/components/Elements';
import { PageHeading } from '@/components/Layout/PageHeading';
import { Authorization, ROLES } from '@/lib/authorization';

import { TPaycycle, usePaycycle } from '../api/getPaycycle';

import { ApprovePaycycle } from './ApprovePaycycle';

const ApprovedDescription = ({ approvedDate }: Pick<TPaycycle, 'approvedDate'>) => {
  return (
    <span className=" font-medium text-ebony-clay-600">
      Your upcoming payments were approved on{' '}
      <span className="text-base font-semibold text-dodger-blue-600">
        {dayjs(approvedDate).format('MMMM DD, YYYY')}
      </span>
      . Your team will receive their payments as stipulated below.
    </span>
  );
};

const PendingDescription = ({ autoApproveDate }: Pick<TPaycycle, 'autoApproveDate'>) => {
  return (
    <span className="font-medium text-ebony-clay-600">
      🔍 Please review your team&rsquo;s payments, including reimbursements, for the upcoming
      paycycle.{' '}
      <Authorization allowedRoles={[ROLES.admin]}>
        👌 If everything looks good, click <span className="font-semibold">Approve</span>.{' '}
      </Authorization>
      If you don&rsquo;t make any changes, all requested payments will be auto-approved on{' '}
      <span className="text-base font-semibold text-dodger-blue-600">
        {dayjs(autoApproveDate).format('MMMM DD, YYYY')}
      </span>
      .
    </span>
  );
};

const PaycycleStats = ({
  endDate,
  paymentDate,
  startDate,
  totalDue,
}: Pick<TPaycycle, 'endDate' | 'paymentDate' | 'startDate' | 'totalDue'>) => (
  <div className="grid grid-cols-1 gap-2  sm:grid-cols-3">
    <div className="flex flex-col bg-ebony-clay-50 px-6 py-5 text-center font-medium shadow-sm">
      <span className="text-ebony-clay-600">Total amount</span>{' '}
      <span className="text-ebony-clay-900">
        ${totalDue} <span className="font-sans text-xs font-bold text-dodger-blue-600">USD</span>
      </span>
    </div>
    <div className="flex flex-col bg-ebony-clay-50 px-6 py-5 text-center font-medium shadow-sm">
      <span className="text-ebony-clay-600">Period</span>{' '}
      <span className="text-ebony-clay-900">
        {dayjs(startDate).format('MMM DD')} - {dayjs(endDate).format('MMM DD')}
      </span>
    </div>
    <div className="flex flex-col bg-ebony-clay-50 px-6 py-5 text-center font-medium shadow-sm">
      <span className="text-ebony-clay-600">Pay by</span>{' '}
      <span className="text-ebony-clay-900">{dayjs(paymentDate).format('MMM DD, YYYY')}</span>
    </div>
  </div>
);

const PaycycleHelperText = ({ autoApproveDate }: Pick<TPaycycle, 'autoApproveDate'>) => {
  if (autoApproveDate)
    return (
      <div>
        <p className="bg-yellow-50 py-3 text-center text-ebony-clay-600">
          ⚠️ If you need to make any changes, such as adding a reimbursement, contact
          <a href="mailto:support@via.work">
            {' '}
            <span className="font-medium underline">support@via.work</span>{' '}
          </a>
          before{' '}
          <span className="text-dodger-blue-600">
            {dayjs(autoApproveDate).format('MMMM DD, YYYY')}
          </span>
          .
        </p>
      </div>
    );

  return (
    <div>
      <p className="bg-yellow-50 py-3 text-center text-ebony-clay-600">
        ⚠️ If you need to make any changes for the next paycycle, please contact us at{' '}
        <a href="mailto:support@via.work">
          {' '}
          <span className="font-medium underline">support@via.work</span>{' '}
        </a>{' '}
        with your change request.
      </p>
    </div>
  );
};

export const ActivePaycycle = () => {
  const paycycleQuery = usePaycycle({
    config: {
      keepPreviousData: true,
    },
  });

  if (paycycleQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!paycycleQuery.data)
    return (
      <div className="bg-ebony-clay-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon className="h-5 w-5 text-ebony-clay-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-ebony-clay-800">
              You do not have an active paycycle at this time
            </h3>
            <div className="mt-2 text-sm text-ebony-clay-700">
              <p>You can start by onboarding a worker.</p>
            </div>
          </div>
        </div>
      </div>
    );

  const activePaycycle = paycycleQuery.data.data;

  return (
    <div className="space-y-6 overflow-hidden border-ebony-clay-100 bg-white text-sm">
      <PageHeading
        title="Active Paycycle 💸"
        description={
          activePaycycle.approvedDate ? (
            <ApprovedDescription approvedDate={activePaycycle.approvedDate} />
          ) : (
            <PendingDescription autoApproveDate={activePaycycle.autoApproveDate} />
          )
        }
        actionComponents={
          <ApprovePaycycle
            key="ApprovePaycycle"
            triggerButton={
              <Button disabled={Boolean(activePaycycle.approvedDate)}>
                Approve{activePaycycle.approvedDate && 'd'}
              </Button>
            }
            id={activePaycycle.id}
          />
        }
      />
      <PaycycleStats
        endDate={activePaycycle.endDate}
        paymentDate={activePaycycle.paymentDate}
        startDate={activePaycycle.startDate}
        totalDue={activePaycycle.totalDue}
      />
      <PaycycleHelperText autoApproveDate={activePaycycle.autoApproveDate} />
    </div>
  );
};
