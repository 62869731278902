import { cloneElement } from 'react';

import { Spinner } from '@/components/Elements';
import { SelectFieldProps } from '@/components/Form';

import { useManagers } from '../api/getManagers';

export const ManagerSelect = ({ children }: { children: JSX.Element }) => {
  const managersQuery = useManagers();

  if (managersQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!managersQuery.data) return null;

  const { data } = managersQuery.data;

  const managersOptions: { label: string; value: string }[] = data.map((manager) => ({
    label: manager.name,
    value: manager.id.toString(),
  }));

  return cloneElement<SelectFieldProps>(children, {
    options: [{ label: '', value: '' }, ...managersOptions],
    placeholder: 'loading',
  });
};
