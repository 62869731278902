import { OnboardWorkerDTO } from '../../api/onboardWorker';

import { OnboardEmployeeSchema } from './schema';

export const onboardEmployeeDTO = (formData: OnboardEmployeeSchema): OnboardWorkerDTO => {
  return {
    worker: { firstName: formData.firstName, lastName: formData.lastName, email: formData.email },
    offer: {
      countryCode: formData.countryCode,
      extraBenefits: {
        needsBenefits: formData.needsBenefits === 'yes' ? true : false,
        newGroupBenefit: formData.newGroupBenefit === 'yes' ? true : false,
        questionnaire: {
          dental: formData.dental || false,
          dependentLife: formData.dependentLife || false,
          groceryAllowance: formData.groceryAllowance || false,
          health: formData.health || false,
          savingsFund: formData.savingsFund || false,
        },
      },
      grossSalaryPeriod: formData.grossSalaryPeriod,
      jobTitle: formData.jobTitle,
      quotedCurrencyCode: formData.quotedCurrencyCode,
      quotedGrossSalary: formData.quotedGrossSalary,
      servicesDescription: formData.servicesDescription,
      startsAt: formData.startsAt,
      statutoryBenefits: {
        bonusDays: formData.bonusDays ? Number(formData.bonusDays) : undefined,
        vacationDays: formData.vacationDays || undefined,
        vacationsPremiumPercentage: formData.vacationsPremiumPercentage
          ? Number(formData.vacationsPremiumPercentage)
          : undefined,
        pto: formData.pto,
      },
      type: 'employee',
      team: formData.team,
    },
  };
};
