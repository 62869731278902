import { SectionHeading } from '@/components/Layout/SectionHeading';

import { EmployeesTakeTable } from '../components/EmployeesTakeTable';
import { SelectPaycycleCurrency } from '../components/SelectPaycycleCurrency';

export const EmployeesTake = () => {
  return (
    <>
      <SectionHeading
        title="What your employees take home 🏡"
        actionComponents={[<SelectPaycycleCurrency key="SelectPaycycleCurrency" />]}
      />
      <EmployeesTakeTable />
    </>
  );
};
