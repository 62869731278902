import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form as FormComponent, FormDrawer, InputField, SelectField } from '@/components/Form';
import { Authorization, ROLES } from '@/lib/authorization';

import { useInviteUser } from '../api/inviteUser';

const schema = z.object({
  fullName: z.string().min(1, 'Required'),
  role: z.string().min(1, 'Required'),
  email: z.string().min(1, 'Required').email(),
});

type FormSchema = z.infer<typeof schema>;

const FORM_ID = 'invite-user';

type FormProps = {
  onSubmit: (values: FormSchema) => Promise<void>;
};

type InfoCardProps = {
  children: string;
};

const InfoCard = ({ children }: InfoCardProps) => (
  <div className="bg-ebony-clay-50 p-6 text-ebony-clay-900">
    <p className="text-sm">{children}</p>
  </div>
);

const Form = ({ onSubmit }: FormProps) => {
  return (
    <FormComponent<FormSchema, typeof schema>
      id={FORM_ID}
      onSubmit={onSubmit}
      schema={schema}
      className="space-y-8 divide-y divide-gray-200"
      options={{
        shouldUnregister: true,
      }}
    >
      {({ formState, register, watch }) => {
        const role = watch('role');

        return (
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-6 border-t border-gray-200 pt-6">
              <InputField
                label="Full name"
                error={formState.errors['fullName']}
                registration={register('fullName')}
              />
              <InputField
                label="Work email address"
                type="email"
                error={formState.errors['email']}
                registration={register('email')}
              />
              <SelectField
                label="Role"
                error={formState.errors['role']}
                registration={register('role')}
                options={[
                  { label: '', value: '' },
                  { label: 'admin', value: 'admin' },
                  { label: 'manager', value: 'manager' },
                  { label: 'viewer', value: 'viewer' },
                ]}
              />
              {role === 'admin' && (
                <InfoCard>
                  Once you complete this form, the new admin will receive an email with instructions
                  for getting onboarded. After onboarding, the new admin will be able to approve
                  paycycles, add new users to the platform, update company details, generate
                  reports, and more.
                </InfoCard>
              )}
              {role === 'manager' && (
                <InfoCard>
                  Once you complete this form, the manager will receive an email with instructions
                  for getting onboarded. After onboarding, managers will be able to review and
                  approve time off and reimbursement requests for your workers.
                </InfoCard>
              )}
              {role === 'viewer' && (
                <InfoCard>
                  Once you complete this form, the viewer will receive an email with instructions
                  for getting onboarded. After onboarding, viewers will be able to see the same
                  information that admins can see, though they won&rsquo;t be able to edit it. This
                  user type is for members of your finance and accounting teams.
                </InfoCard>
              )}
            </div>
          </div>
        );
      }}
    </FormComponent>
  );
};

export const InviteUserForm = () => {
  const inviteUserMutation = useInviteUser();

  const handleOnSubmit = async (formData: FormSchema) => {
    await inviteUserMutation.mutateAsync({
      email: formData.email,
      role: formData.role,
      fullName: formData.fullName,
    });
  };

  return (
    <Authorization allowedRoles={[ROLES.admin]}>
      <FormDrawer
        isDone={inviteUserMutation.isSuccess}
        title="User Information"
        description="Aftering you complete this form, the user will receive an email with instructions for getting onboarded."
        triggerButton={<Button>Invite User</Button>}
        submitButton={
          <Button
            form={FORM_ID}
            type="submit"
            isLoading={inviteUserMutation.isLoading}
            disabled={inviteUserMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form onSubmit={handleOnSubmit} />
      </FormDrawer>
    </Authorization>
  );
};
