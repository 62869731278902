import { PlusIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { IconButton } from '@/components/Elements/IconButton';
import {
  Form as FormComponent,
  FormDrawer,
  InputField,
  SelectField,
  TextAreaField,
} from '@/components/Form';
import { isAfterDate } from '@/utils/validate';

import { useAddTimeOff } from '../api/addTimeOff';

const schema = z
  .object({
    endDate: z.string().min(1, 'Required'),
    startDate: z.string().min(1, 'Required'),
    type: z.string().min(1, 'Required'),
    comment: z.string().max(255, 'Comment should be less than 256 characters').optional(),
  })
  .refine(
    ({ startDate, endDate }) =>
      endDate ? isAfterDate(endDate, dayjs(startDate).subtract(1, 'day').toString()) : true,
    {
      path: ['endDate'],
      message: 'End date should be after or equal to start date',
    }
  );

type FormSchema = z.infer<typeof schema>;

const FORM_ID = 'add-time-off';

type FormProps = {
  workerFullName?: string;
  onSubmit: (values: FormSchema) => Promise<void>;
};

const Form = ({ workerFullName, onSubmit }: FormProps) => {
  return (
    <FormComponent<FormSchema, typeof schema>
      id={FORM_ID}
      onSubmit={onSubmit}
      schema={schema}
      className="space-y-8 divide-y divide-gray-200"
      options={{
        shouldUnregister: true,
      }}
    >
      {({ formState, register }) => {
        return (
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-6 border-t border-gray-200 pt-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Would you like to add time off to{' '}
                  <span className="text-dodger-blue-600">{workerFullName}</span>?
                </h3>
              </div>
              <SelectField
                label="What kind of time off is it?"
                error={formState.errors['type']}
                registration={register('type')}
                options={[
                  { label: '', value: '' },
                  { label: 'Vacation', value: 'vacation' },
                  { label: 'Sick', value: 'sick' },
                  { label: 'Parental leave', value: 'parental-leave' },
                  { label: 'Other', value: 'other' },
                ]}
              />
              <InputField
                label="Start date"
                type="date"
                error={formState.errors['startDate']}
                registration={register('startDate', { deps: ['endDate'] })}
              />
              <InputField
                label="End date"
                type="date"
                error={formState.errors['endDate']}
                registration={register('endDate')}
              />
              <TextAreaField
                label="Comment (optional)"
                error={formState.errors['comment']}
                registration={register('comment')}
              />
            </div>
          </div>
        );
      }}
    </FormComponent>
  );
};

type AddTimeOffFormProps = {
  fullName: string;
  id: number;
};

export const AddTimeOffForm = ({ fullName, id }: AddTimeOffFormProps) => {
  const addTimeOffMutation = useAddTimeOff();

  const handleOnSubmit = async (formData: FormSchema) => {
    await addTimeOffMutation.mutateAsync({
      workerId: id,
      startDate: formData.startDate,
      endDate: formData.endDate,
      comment: formData.comment,
      type: formData.type,
    });
  };

  return (
    <FormDrawer
      isDone={addTimeOffMutation.isSuccess}
      title="Add time off"
      triggerButton={
        <IconButton>
          <PlusIcon />
        </IconButton>
      }
      submitButton={
        <Button
          form={FORM_ID}
          type="submit"
          isLoading={addTimeOffMutation.isLoading}
          disabled={addTimeOffMutation.isLoading}
        >
          Submit
        </Button>
      }
    >
      <Form onSubmit={handleOnSubmit} workerFullName={fullName} />
    </FormDrawer>
  );
};
