import { Button } from '@/components/Elements';
import { useEmailResend } from '@/features/auth';
import { useAuth } from '@/lib/auth';
import { useNotificationStore } from '@/stores/notifications';
import { windowReplaceOriginUrl } from '@/utils/url';

import { Layout } from '../components/Layout';

export const Finished = () => {
  const { logout, isLoggingOut, user } = useAuth();
  const emailResendMutation = useEmailResend();
  const { addNotification } = useNotificationStore();

  const isVerified = user?.data.verified;
  const isInvitedUser = user?.data.company.status === 'active' && !user.data.verified;

  const handleSignOut = () => {
    logout();
  };
  const handleResendEmail = () => {
    emailResendMutation.mutate(
      {},
      {
        onSuccess: () => {
          addNotification({
            type: 'success',
            title: 'Email sent',
          });
        },
      }
    );
  };

  const handleContinue = () => {
    windowReplaceOriginUrl();
  };

  return (
    <Layout withSignOut={false}>
      <div className="text-center">
        <h3 className="mt-6 text-sm font-medium text-gray-900">
          {isInvitedUser
            ? `You have been invited to join ${user?.data.company.name}!`
            : 'Thank you for registering your company with Via!'}
        </h3>
        {!isVerified ? (
          <>
            <p className="mt-1 text-sm text-gray-500">
              You’re almost there! We sent a verification email to your company email address.
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Please verify your email address so that you can get started onboarding your global
              team.
            </p>
            <div className="mt-6">
              <Button
                isLoading={emailResendMutation.isLoading}
                disabled={emailResendMutation.isLoading}
                size="lg"
                onClick={handleResendEmail}
              >
                Resend verification email
              </Button>
            </div>
            <div className="mt-6">
              <button
                disabled={isLoggingOut}
                onClick={handleSignOut}
                className="text-froly-500 hover:text-froly-400 focus:underline focus:underline-offset-2 focus:outline-none"
              >
                Sign out
              </button>
            </div>
          </>
        ) : (
          <Button size="lg" onClick={handleContinue}>
            Continue to dashboard
          </Button>
        )}
      </div>
    </Layout>
  );
};
