import { PlusIcon } from '@heroicons/react/outline';

import { Button } from '@/components/Elements';
import { FormDrawer } from '@/components/Form';
import { Authorization, ROLES } from '@/lib/authorization';

import { useOnboardWorker } from '../../api/onboardWorker';

import { ActionForm, FORM_ID } from './ActionForm';
import { onboardEmployeeDTO } from './onboardEmployeeDTO';
import { OnboardEmployeeSchema } from './schema';

export const OnboardEmployee = () => {
  const onboardWorkerMutation = useOnboardWorker();
  const handleOnSubmit = async (formData: OnboardEmployeeSchema) => {
    await onboardWorkerMutation.mutateAsync(onboardEmployeeDTO(formData));
  };

  return (
    <Authorization allowedRoles={[ROLES.admin]}>
      <FormDrawer
        isDone={onboardWorkerMutation.isSuccess}
        description="Fill out your new worker&rsquo;s information to start the onboarding process."
        title="Add a New Employee to Your Team"
        triggerButton={
          <Button startIcon={<PlusIcon className="h-4 w-4" />} size="md">
            Add employee
          </Button>
        }
        submitButton={
          <Button
            form={FORM_ID}
            type="submit"
            isLoading={onboardWorkerMutation.isLoading}
            disabled={onboardWorkerMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <ActionForm onSubmit={handleOnSubmit} />
      </FormDrawer>
    </Authorization>
  );
};
