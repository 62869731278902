import { ArrowRightIcon, BellIcon } from '@heroicons/react/outline';
import { XCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { useState } from 'react';

import { Drawer, Link } from '@/components/Elements';
import { TextButton } from '@/components/Elements/TextButton';
import { formatDateByMonth } from '@/utils/format';

import { useDismissPendingNotification } from '../api/dismissPendingNotification';
import { usePendingNotifications } from '../api/getPendingNotifications';

type TriggerButtonProps = {
  hasPendingNotifications?: boolean;
  openDrawer?: () => void;
};

const TriggerButton = ({ hasPendingNotifications = false, openDrawer }: TriggerButtonProps) => {
  return (
    <button
      className={clsx(
        'rounded-full p-2',
        hasPendingNotifications
          ? 'text-ebony-clay-400 hover:text-ebony-clay-600'
          : 'text-ebony-clay-300'
      )}
      onClick={openDrawer && openDrawer}
      disabled={!hasPendingNotifications}
    >
      <div className="relative">
        <BellIcon className="h-6 w-6" />
        {hasPendingNotifications && (
          <div
            className="absolute top-0 right-0 block h-3 w-3 rounded-full bg-froly-500 ring-2 ring-white"
            aria-hidden="true"
          >
            <span className="relative bottom-[7.5px] text-[11px] font-bold text-white">!</span>
          </div>
        )}
      </div>
    </button>
  );
};

type PendingNotificationProps = {
  description: string;
  dismissButton: JSX.Element;
  link: string;
  title: string;
};

function PendingNotification({
  description,
  dismissButton,
  link,
  title,
}: PendingNotificationProps) {
  return (
    <div className="group relative flex items-center py-6 px-5">
      <div className="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true" />
      <div className="relative flex min-w-0 flex-1">
        <BellIcon className="h-8 w-8 text-ebony-clay-400" />
        <div className="ml-4 text-sm">
          <div className="mb-2">
            <p className="font-medium text-gray-900">{title}</p>
            <p className="text-gray-500">{description}</p>
          </div>
          <Link to={link} className="rounded-3xl">
            <TextButton
              tabIndex={-1}
              endIcon={<ArrowRightIcon className="h-4 w-4 text-dodger-blue-500" />}
            >
              Review
            </TextButton>
          </Link>
        </div>
        <div>{dismissButton}</div>
      </div>
    </div>
  );
}

type DismissButtonProps = {
  id: string;
};

function DismissButton({ id }: DismissButtonProps) {
  const dismissPendingNotificationMutation = useDismissPendingNotification();

  const handleOnDismiss = async () => {
    await dismissPendingNotificationMutation.mutateAsync({ id });
  };

  return (
    <button
      onClick={handleOnDismiss}
      type="button"
      disabled={dismissPendingNotificationMutation.isLoading}
      className="rounded-full bg-white text-ebony-clay-400 hover:text-ebony-clay-500 focus:ring-2 focus:ring-dodger-blue-500 disabled:text-ebony-clay-400"
    >
      <span className="sr-only">Dismiss notification</span>
      <XCircleIcon className="h-5 w-5" />
    </button>
  );
}

export const PendingNotifications = () => {
  const [open, setOpen] = useState(false);

  const pendingNotificationsQuery = usePendingNotifications({
    config: {
      refetchOnWindowFocus: true,
    },
  });

  const openDrawer = () => {
    setOpen(true);
  };
  const closeDrawer = () => {
    setOpen(false);
  };

  if (pendingNotificationsQuery.isLoading) return <TriggerButton />;

  if (!pendingNotificationsQuery.data) return null;

  const pendingNotifications = pendingNotificationsQuery.data.data;
  const hasPendingNotifications = pendingNotifications.length > 0;

  return (
    <>
      <TriggerButton openDrawer={openDrawer} hasPendingNotifications={hasPendingNotifications} />
      <Drawer onClose={closeDrawer} isOpen={open} title="Notifications" size="sm">
        <ul className="flex-1 divide-y divide-gray-200 overflow-y-auto border-t">
          {pendingNotifications.map((pendingNotification) => (
            <li key={pendingNotification.id}>
              <PendingNotification
                description={pendingNotification.data.subject}
                dismissButton={<DismissButton id={pendingNotification.id} />}
                link={pendingNotification.data.link}
                title={formatDateByMonth(pendingNotification.createdAt)}
              />
            </li>
          ))}
        </ul>
      </Drawer>
    </>
  );
};
