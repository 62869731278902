import { Spinner } from '@/components/Elements';
import { AssignManagerForm } from '@/features/users';

import { useWorker } from '../api/getWorker';

export const WorkerDetails = () => {
  const workerQuery = useWorker();

  if (workerQuery.isLoading) {
    return (
      <div className="flex items-center justify-center rounded-3xl border p-4">
        <Spinner />
      </div>
    );
  }

  if (!workerQuery.data) return null;

  const worker = workerQuery.data.data;

  return (
    <>
      <h3 className="text-lg font-medium text-ebony-clay-900">Worker details</h3>
      <div className="border-ebony-clay-200">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-ebony-clay-500">Personal email address</dt>
            <dd className="mt-1 text-sm text-ebony-clay-900">{worker.email}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-ebony-clay-500">Birth date</dt>
            <dd className="mt-1 text-sm text-ebony-clay-900">{worker.birthDate}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-ebony-clay-500">Phone number</dt>
            <dd className="mt-1 text-sm text-ebony-clay-900">{worker.phoneNumber}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-ebony-clay-500">Status</dt>
            <dd className="mt-1 text-sm capitalize text-ebony-clay-900">{worker.offer.status}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-ebony-clay-500">Start date</dt>
            <dd className="mt-1 text-sm text-ebony-clay-900">{worker.offer.startDate}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-ebony-clay-500">End date</dt>
            <dd className="mt-1 text-sm text-ebony-clay-900">
              {worker.offer.endDate || 'Indefinite'}
            </dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-ebony-clay-500">Description of services</dt>
            <dd className="mt-1 text-sm text-ebony-clay-900">{worker.offer.servicesDescription}</dd>
          </div>
        </dl>
      </div>
      <h3 className="text-lg font-medium text-ebony-clay-900">Payment Method</h3>
      <div className="border-ebony-clay-200">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-ebony-clay-500">Account</dt>
            <dd className="mt-1 text-sm text-ebony-clay-900">
              {worker.offer.bankDetails?.details.account}
            </dd>
          </div>
          {worker.offer.bankDetails?.details.type && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-ebony-clay-500">Account type</dt>
              <dd className="mt-1 text-sm text-ebony-clay-900">
                {worker.offer.bankDetails.details.type}
              </dd>
            </div>
          )}
          {worker.offer.bankDetails?.details.clabe && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-ebony-clay-500">CLABE</dt>
              <dd className="mt-1 text-sm text-ebony-clay-900">
                {worker.offer.bankDetails.details.clabe}
              </dd>
            </div>
          )}
          {worker.offer.bankDetails?.details.institutionNumber && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-ebony-clay-500">Institution number</dt>
              <dd className="mt-1 text-sm text-ebony-clay-900">
                {worker.offer.bankDetails.details.institutionNumber}
              </dd>
            </div>
          )}
          {worker.offer.bankDetails?.details.routingNumber && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-ebony-clay-500">Routing number</dt>
              <dd className="mt-1 text-sm text-ebony-clay-900">
                {worker.offer.bankDetails.details.routingNumber}
              </dd>
            </div>
          )}
          {worker.offer.bankDetails?.details.swiftBic && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-ebony-clay-500">Swift / Bic</dt>
              <dd className="mt-1 text-sm text-ebony-clay-900">
                {worker.offer.bankDetails.details.swiftBic}
              </dd>
            </div>
          )}
          {worker.offer.bankDetails?.details.transitCode && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-ebony-clay-500">Transit code</dt>
              <dd className="mt-1 text-sm text-ebony-clay-900">
                {worker.offer.bankDetails.details.transitCode}
              </dd>
            </div>
          )}
        </dl>
      </div>
      <h3 className="text-lg font-medium text-ebony-clay-900">Manager</h3>
      <div className="border-ebony-clay-200">
        <dl className="grid grid-cols-1 items-center gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-ebony-clay-500">Name</dt>
            <dd className="mt-1 flex justify-between text-sm text-ebony-clay-900">
              {worker.offer.manager.name || 'No manager selected'}{' '}
              <AssignManagerForm workerId={worker.id} defaultManagerId={worker.offer.manager.id} />
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
};
