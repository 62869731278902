import { Navigate, Route, Routes } from 'react-router-dom';

import { CompanyPays } from './CompanyPays';
import { EmployeesTake } from './EmployeesTake';
import { Paycycle } from './Paycycle';

export const PaycycleRoutes = () => (
  <Routes>
    <Route path="" element={<Paycycle />}>
      <Route path="" element={<CompanyPays />} />
      <Route path="employees-take" element={<EmployeesTake />} />
    </Route>
    <Route path="*" element={<Navigate to="" />} />
  </Routes>
);
