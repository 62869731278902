import clsx from 'clsx';
import { ChangeEvent } from 'react';
import { useSearchParams } from 'react-router-dom';

type Option = {
  label: string;
  value: string;
};

type FilterProps = {
  name: string;
  options: Option[];
};

export const Filter = ({ name, options }: FilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultValue = searchParams.get(`filter[${name}]`) || undefined;

  const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    searchParams.delete(`filter[${name}]`);
    searchParams.delete('page');
    if (event.currentTarget.value) {
      searchParams.append(`filter[${name}]`, event.currentTarget.value);
    }
    setSearchParams(searchParams);
  };

  return (
    <select
      className={clsx(
        'block w-full appearance-none',
        'rounded-3xl border border-gray-300',
        'px-3 py-2 capitalize shadow-sm',
        'focus:border-dodger-blue-600 focus:ring-dodger-blue-600',
        'truncate sm:text-sm'
      )}
      translate="no"
      onChange={handleOnChange}
      defaultValue={defaultValue}
    >
      {options.map(({ label, value }) => (
        <option key={label} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};
