import { AxiosResponse } from 'axios';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';

export const getReviewedAdditionalCompensationsXLS = (
  page?: string,
  sort?: string,
  createdBetween?: string,
  status?: string,
  type?: string
): Promise<AxiosResponse<Blob, any>> =>
  axios.get(`${API_BASE_PATH}/workers/additional-compensations`, {
    params: {
      'filter[status]': status,
      'filter[createdBetween]': createdBetween,
      'filter[type]': type,
      sort,
      page,
    },
    headers: {
      accept: 'application/octet-stream',
    },
    responseType: 'blob',
  });
