import { PlusIcon } from '@heroicons/react/outline';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { IconButton } from '@/components/Elements/IconButton';
import {
  Form as FormComponent,
  FormDrawer,
  InputField,
  SelectField,
  TextAreaField,
} from '@/components/Form';
import { CurrencySelect } from '@/features/countries';
import { isValidCurrency } from '@/utils/validate';

import { useAddReimbursement } from '../api/addReimbursement';

const schema = z
  .object({
    amount: z.string().min(1, 'Required'),
    currencyCode: z.string().min(1, 'Required'),
    description: z
      .string()
      .min(1, 'Description should be less than 1 characters')
      .max(255, 'Description should be less than 256 characters'),
    type: z.string().min(1, 'Required'),
  })
  .refine(({ amount, currencyCode }) => isValidCurrency(amount, currencyCode), {
    path: ['amount'],
    message: 'Must be a valid amount',
  });

type FormSchema = z.infer<typeof schema>;

const FORM_ID = 'add-reimbursement';

type FormProps = {
  workerFullName?: string;
  onSubmit: (values: FormSchema) => Promise<void>;
};

const Form = ({ workerFullName, onSubmit }: FormProps) => {
  return (
    <FormComponent<FormSchema, typeof schema>
      id={FORM_ID}
      onSubmit={onSubmit}
      schema={schema}
      className="space-y-8 divide-y divide-gray-200"
      options={{
        shouldUnregister: true,
      }}
    >
      {({ formState, register }) => {
        return (
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-6 border-t border-gray-200 pt-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Would you like to add additional payment to{' '}
                  <span className="text-dodger-blue-600">{workerFullName}</span>?
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  <span className="font-medium">Note:</span> Bonus, commissions, and other variable
                  compensation will incur in tax withholdings, this will result in a lower payment
                  received by the employee. If you have any questions, please reachout to
                  support@via.work.
                </p>
              </div>
              <SelectField
                label="Type"
                error={formState.errors['type']}
                registration={register('type')}
                options={[
                  { label: '', value: '' },
                  { label: 'Bonus', value: 'bonus' },
                  { label: 'Commission', value: 'commission' },
                  { label: 'Reimbursement', value: 'reimbursement' },
                  { label: 'Other', value: 'other' },
                ]}
              />
              <CurrencySelect>
                <SelectField
                  label="Currency"
                  error={formState.errors['currencyCode']}
                  registration={register('currencyCode')}
                  options={[
                    { label: '', value: '' },
                    { label: 'USD', value: 'USD' },
                  ]}
                />
              </CurrencySelect>
              <InputField
                label="Amount"
                error={formState.errors['amount']}
                registration={register('amount')}
              />
              <TextAreaField
                label="Description"
                error={formState.errors['description']}
                registration={register('description')}
              />
            </div>
          </div>
        );
      }}
    </FormComponent>
  );
};

type AddReimbursementFormProps = {
  fullName: string;
  id: number;
};

export const AddReimbursementForm = ({ fullName, id }: AddReimbursementFormProps) => {
  const addReimbursementMutation = useAddReimbursement();

  const handleOnSubmit = async (formData: FormSchema) => {
    await addReimbursementMutation.mutateAsync({
      amount: Number(formData.amount),
      currencyCode: formData.currencyCode,
      description: formData.description,
      payee: { id },
      type: formData.type,
    });
  };

  return (
    <FormDrawer
      isDone={addReimbursementMutation.isSuccess}
      description="You can add any additional payments for stipends, bonuses, and reimbursements. If you submit the funds and payment requests before the 5th of the month, bonus payments will be paid out during that month’s paycycle. Otherwise, the payments will be included in the next month’s paycycle."
      title="Add reimbursement"
      triggerButton={
        <IconButton>
          <PlusIcon />
        </IconButton>
      }
      submitButton={
        <Button
          form={FORM_ID}
          type="submit"
          isLoading={addReimbursementMutation.isLoading}
          disabled={addReimbursementMutation.isLoading}
        >
          Submit
        </Button>
      }
    >
      <Form onSubmit={handleOnSubmit} workerFullName={fullName} />
    </FormDrawer>
  );
};
