import { ReactNode } from 'react';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { TextButton } from '@/components/Elements/TextButton';
import { Form as FormComponent, FormDrawer, SelectField } from '@/components/Form';
import { Authorization, ROLES } from '@/lib/authorization';

import { useAssignManager } from '../api/assignManager';

import { ManagerSelect } from './ManagerSelect';

const schema = z.object({
  managerId: z.string().min(1, 'Required'),
});

type FormSchema = z.infer<typeof schema>;

const FORM_ID = 'assign-manager';

type InfoCardProps = {
  children: ReactNode;
};

const InfoCard = ({ children }: InfoCardProps) => (
  <div className="bg-ebony-clay-50 p-6 text-sm text-ebony-clay-900">{children}</div>
);

type FormProps = {
  defaultValues?: Partial<FormSchema>;
  onSubmit: (values: FormSchema) => Promise<void>;
};

const Form = ({ defaultValues, onSubmit }: FormProps) => {
  return (
    <FormComponent<FormSchema, typeof schema>
      id={FORM_ID}
      onSubmit={onSubmit}
      schema={schema}
      className="space-y-8 divide-y divide-gray-200"
      options={{
        defaultValues,
        shouldUnregister: true,
      }}
    >
      {({ formState, register }) => {
        return (
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-6 border-t border-gray-200 pt-6">
              <InfoCard>
                <p>Any managers you add to your team will be able to:</p>
                <ul className="list-inside list-disc">
                  <li>Access the Additional Payments and Time Off modules</li>
                  <li>Approve or deny reimbursements and time-off requests</li>
                  <li>View the workers that are assigned to them on the team dashboard</li>
                </ul>
              </InfoCard>
              <ManagerSelect>
                <SelectField
                  label="Manager"
                  error={formState.errors['managerId']}
                  registration={register('managerId')}
                  options={[{ label: '', value: '' }]}
                />
              </ManagerSelect>
            </div>
          </div>
        );
      }}
    </FormComponent>
  );
};

type AssignManagerFormProps = {
  defaultManagerId?: string;
  workerId: number;
};

export const AssignManagerForm = ({ defaultManagerId, workerId }: AssignManagerFormProps) => {
  const assignManager = useAssignManager();

  const handleOnSubmit = async (formData: FormSchema) => {
    await assignManager.mutateAsync({
      userId: formData.managerId,
      workerId,
    });
  };

  return (
    <Authorization allowedRoles={[ROLES.admin]}>
      <FormDrawer
        isDone={assignManager.isSuccess}
        title="Assign Manager"
        description="Select the worker&rsquo;s manager. If you do not see the manager&rsquo;s name, you can add him/her on the User Dashboard."
        triggerButton={<TextButton>Update</TextButton>}
        submitButton={
          <Button
            form={FORM_ID}
            type="submit"
            isLoading={assignManager.isLoading}
            disabled={assignManager.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form defaultValues={{ managerId: defaultManagerId }} onSubmit={handleOnSubmit} />
      </FormDrawer>
    </Authorization>
  );
};
