import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { APP_BASE_PATH } from '@/config';
import { AdditionalCompensationsRoutes } from '@/features/additionalCompensations';
import { CompanyProfile } from '@/features/company';
import { PaycycleRoutes } from '@/features/paycycle';
import { ReportsRoutes } from '@/features/reports';
import { TimeOffRoutes } from '@/features/timeOff';
import { UsersDashboard } from '@/features/users';
import { TeamDashboard, WorkerRoutes } from '@/features/workers';
import { Authorization, ROLES } from '@/lib/authorization';

const App = () => (
  <MainLayout>
    <Suspense
      fallback={
        <div className="flex h-full w-full items-center justify-center">
          <Spinner size="xl" />
        </div>
      }
    >
      <Outlet />
    </Suspense>
  </MainLayout>
);

const ForbiddenFallback = () => <Navigate to={'/app/team'} />;

export const protectedRoutes = [
  {
    path: `${APP_BASE_PATH}/*`,
    element: <App />,
    children: [
      {
        path: 'company',
        element: (
          <Authorization forbiddenFallback={<ForbiddenFallback />} allowedRoles={[ROLES.admin]}>
            <CompanyProfile />
          </Authorization>
        ),
      },
      {
        path: 'team',
        element: (
          <Authorization
            forbiddenFallback={<ForbiddenFallback />}
            allowedRoles={[ROLES.admin, ROLES.viewer, ROLES.manager]}
          >
            <TeamDashboard />
          </Authorization>
        ),
      },
      {
        path: 'additional-pay/*',
        element: (
          <Authorization
            forbiddenFallback={<ForbiddenFallback />}
            allowedRoles={[ROLES.admin, ROLES.viewer, ROLES.manager]}
          >
            <AdditionalCompensationsRoutes />
          </Authorization>
        ),
      },
      {
        path: 'time-off/*',
        element: (
          <Authorization
            forbiddenFallback={<ForbiddenFallback />}
            allowedRoles={[ROLES.admin, ROLES.viewer, ROLES.manager]}
          >
            <TimeOffRoutes />
          </Authorization>
        ),
      },
      {
        path: 'reports/*',
        element: (
          <Authorization
            forbiddenFallback={<ForbiddenFallback />}
            allowedRoles={[ROLES.admin, ROLES.viewer]}
          >
            <ReportsRoutes />
          </Authorization>
        ),
      },
      {
        path: 'paycycle/*',
        element: (
          <Authorization
            forbiddenFallback={<ForbiddenFallback />}
            allowedRoles={[ROLES.admin, ROLES.viewer]}
          >
            <PaycycleRoutes />
          </Authorization>
        ),
      },
      {
        path: 'users/*',
        element: (
          <Authorization forbiddenFallback={<ForbiddenFallback />} allowedRoles={[ROLES.admin]}>
            <UsersDashboard />
          </Authorization>
        ),
      },
      {
        path: 'team/worker/*',
        element: (
          <Authorization
            forbiddenFallback={<ForbiddenFallback />}
            allowedRoles={[ROLES.admin, ROLES.viewer, ROLES.manager]}
          >
            <WorkerRoutes />
          </Authorization>
        ),
      },
      {
        path: '*',
        element: (
          <Authorization
            forbiddenFallback={<ForbiddenFallback />}
            allowedRoles={[ROLES.admin, ROLES.viewer]}
          >
            <Navigate to={`${APP_BASE_PATH}/paycycle`} />
          </Authorization>
        ),
      },
    ],
  },
  {
    path: '/*',
    element: <Navigate to={`${APP_BASE_PATH}`} />,
  },
];
