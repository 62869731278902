import {
  Link,
  Pagination,
  Spinner,
  Table as TableComponent,
  TableProps,
} from '@/components/Elements';
import { useAuth } from '@/lib/auth';
import { ROLES, useAuthorization } from '@/lib/authorization';

import { useTimeOffRequests } from '../../api/getTimeOffRequests';
import { AcceptTimeOff } from '../AcceptTimeOff';
import { DeclineTimeOff } from '../DeclineTimeOff';

import { DownloadToReviewXLS } from './DownloadToReviewXLS';

type TTimeOffColumn = {
  avatar: string;
  id: number;
  fullName: string;
  type: string;
  createdAt: string;
  description: string;
  startDate: string;
  endDate: string;
};

const tableColumns: TableProps<TTimeOffColumn>['columns'] = [
  {
    title: 'Full Name',
    field: 'fullName',
    Cell: ({ entry: { avatar, fullName, id } }) => (
      <div className="flex items-center gap-x-2">
        <img className="mr-1 h-8 w-8 rounded-full" src={avatar} alt="user avatar" />
        <Link to={`/app/team/worker/${id}`}>
          <span className="font-medium text-ebony-clay-900">{fullName}</span>
        </Link>
      </div>
    ),
  },
  {
    title: 'Request Type',
    field: 'type',
    Cell: ({ entry: { type } }) => <p className="capitalize-first">{type}</p>,
  },
  {
    title: 'Request Date',
    field: 'createdAt',
  },
  {
    title: 'Start Date',
    field: 'startDate',
    isSortable: false,
  },
  {
    title: 'End Date',
    field: 'endDate',
    isSortable: false,
  },
  {
    title: 'Description',
    field: 'description',
    isFullWidth: true,
    isSortable: false,
  },
];

export const Table = () => {
  const { user } = useAuth();

  const { checkAccess } = useAuthorization();
  const timeOffRequestsQuery = useTimeOffRequests({
    config: {
      keepPreviousData: true,
    },
  });

  if (timeOffRequestsQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!timeOffRequestsQuery.data) return null;

  const { data, links, meta } = timeOffRequestsQuery.data;

  const timeOffRequests: TTimeOffColumn[] = data.map((request) => ({
    avatar: request.avatar,
    fullName: request.fullName,
    id: request.id,
    type: request.type,
    createdAt: request.createdAt,
    description: request.description,
    startDate: request.startDate,
    endDate: request.endDate,
  }));

  const columns = [
    ...(checkAccess({ allowedRoles: [ROLES.admin, ROLES.manager] })
      ? [
          {
            title: '',
            field: 'id',
            isNarrowColumn: true,
            isSortable: false,
            Cell({
              entry: { id, fullName, endDate, startDate, type, description },
            }: {
              entry: TTimeOffColumn;
            }) {
              return (
                <div className="flex gap-x-2">
                  <AcceptTimeOff
                    id={id}
                    fullName={fullName}
                    endDate={endDate}
                    startDate={startDate}
                    requestType={type}
                    description={description}
                  />
                  <DeclineTimeOff
                    id={id}
                    fullName={fullName}
                    endDate={endDate}
                    startDate={startDate}
                    requestType={type}
                    description={description}
                  />
                </div>
              );
            },
          },
        ]
      : []),
    ...tableColumns,
  ].filter(Boolean) as TableProps<TTimeOffColumn>['columns'];

  return (
    <TableComponent<TTimeOffColumn>
      data={timeOffRequests}
      emptyState="No pending time off requests"
      isFetching={timeOffRequestsQuery.isFetching && timeOffRequestsQuery.isPreviousData}
      columns={columns}
      downloadXLS={
        <DownloadToReviewXLS
          companyName={user?.data.company.name || ''}
          title="To Review Time Off"
        />
      }
      pagination={
        <Pagination
          links={links}
          meta={meta}
          path="/time-off"
          isFetching={timeOffRequestsQuery.isFetching && timeOffRequestsQuery.isPreviousData}
        />
      }
    />
  );
};
