import { SectionHeading } from '@/components/Layout/SectionHeading';

import { ReviewedAdditionalPaymentsTable } from '../components/ReviewedAdditionalPaymentsTable/ReviewedAdditionalPaymentsTable';

export const Reviewed = () => {
  return (
    <>
      <SectionHeading title="Thanks for reviewing your additional compensation requests! 🚀" />
      <ReviewedAdditionalPaymentsTable />
    </>
  );
};
