import * as z from 'zod';

import { Button } from '@/components/Elements';
import { TextButton } from '@/components/Elements/TextButton';
import { Form as FormComponent, FormDrawer, SelectField } from '@/components/Form';

import { useUpdateUser } from '../api/updateUser';

const schema = z.object({
  role: z.string().min(1, 'Required'),
});

type FormSchema = z.infer<typeof schema>;

const FORM_ID = 'update-user';

type FormProps = {
  defaultValues: {
    email: string;
    fullName: string;
    role: string;
  };
  onSubmit: (values: FormSchema) => Promise<void>;
};

type InfoCardProps = {
  children: string;
};

const WarningCard = ({ children }: InfoCardProps) => (
  <div className="bg-yellow-50 p-6 text-yellow-900">
    <p className="text-sm">{children}</p>
  </div>
);

const InfoCard = ({ children }: InfoCardProps) => (
  <div className="bg-ebony-clay-50 p-6 text-ebony-clay-900">
    <p className="text-sm">{children}</p>
  </div>
);

const Form = ({ defaultValues, onSubmit }: FormProps) => {
  return (
    <FormComponent<FormSchema, typeof schema>
      id={FORM_ID}
      onSubmit={onSubmit}
      schema={schema}
      className="space-y-8 divide-y divide-ebony-clay-200"
      options={{
        defaultValues,
        shouldUnregister: true,
      }}
    >
      {({ formState, register, watch }) => {
        const role = watch('role');

        return (
          <div className="space-y-8 divide-y divide-ebony-clay-200">
            <div className="space-y-6 border-t border-ebony-clay-200 pt-6">
              <dl className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <WarningCard>
                    ⚠️ Be sure to confirm the user&rsquo;s full name and email address
                  </WarningCard>
                </div>
                <div className="bg-ebony-clay-50 p-6 sm:col-span-1">
                  <dt className="text-sm font-medium text-ebony-clay-500">Full name</dt>
                  <dd className="mt-1 text-sm text-ebony-clay-900">{defaultValues.fullName}</dd>
                </div>
                <div className="bg-ebony-clay-50 p-6 sm:col-span-1">
                  <dt className="text-sm font-medium text-ebony-clay-500">Email</dt>
                  <dd className="mt-1 text-sm text-ebony-clay-900">{defaultValues.email}</dd>
                </div>
              </dl>
              <SelectField
                label="Role"
                error={formState.errors['role']}
                registration={register('role')}
                options={[
                  { label: '', value: '' },
                  { label: 'admin', value: 'admin' },
                  { label: 'manager', value: 'manager' },
                  { label: 'viewer', value: 'viewer' },
                ]}
              />
              {role === 'admin' && (
                <InfoCard>
                  Admins are able to approve paycycles, add new users to the platform, update
                  company details, generate reports, and more.
                </InfoCard>
              )}
              {role === 'manager' && (
                <InfoCard>
                  Managers are able to review and approve time off and reimbursement requests for
                  your workers.
                </InfoCard>
              )}
              {role === 'viewer' && (
                <InfoCard>
                  Viewers are able to see the same information that admins can see, though they
                  won&rsquo;t be able to edit it. This user type is for members of your finance and
                  accounting teams.
                </InfoCard>
              )}
            </div>
          </div>
        );
      }}
    </FormComponent>
  );
};

type UpdateUserFormProps = {
  defaultValues: FormProps['defaultValues'];
  id: number;
};

export const UpdateUserForm = ({ defaultValues, id }: UpdateUserFormProps) => {
  const updateUserMutation = useUpdateUser();

  const handleOnSubmit = async (formData: FormSchema) => {
    await updateUserMutation.mutateAsync({
      id,
      role: formData.role,
    });
  };

  return (
    <FormDrawer
      isDone={updateUserMutation.isSuccess}
      title="Update User Role"
      description="Update or change a user&rsquo;s role"
      triggerButton={<TextButton>Update Role</TextButton>}
      submitButton={
        <Button
          form={FORM_ID}
          type="submit"
          isLoading={updateUserMutation.isLoading}
          disabled={updateUserMutation.isLoading}
        >
          Update
        </Button>
      }
    >
      <Form defaultValues={defaultValues} onSubmit={handleOnSubmit} />
    </FormDrawer>
  );
};
