import { DownloadIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TextButton } from '@/components/Elements/TextButton';
import { useDownloadFile } from '@/hooks/useDownloadFile';

import { getReviewedAdditionalCompensationsXLS } from '../../api/getReviewedAdditionalCompensationsXLS';

export const DownloadReviewedAdditionalCompensationsXLS = ({
  companyName,
  title,
}: {
  companyName: string;
  title: string;
}) => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || undefined;
  const sort = searchParams.get('sort') || undefined;
  const createdBetween = searchParams.get('filter[createdBetween]') || undefined;
  const status = searchParams.get('filter[status]') || undefined;
  const type = searchParams.get('filter[type]') || undefined;

  const [buttonState, setButtonState] = useState<boolean>();

  const preDownloading = () => setButtonState(true);
  const postDownloading = () => setButtonState(false);

  const onErrorDownloadFile = () => {
    setButtonState(false);
  };

  const getFileName = () => {
    return `${title} - ${companyName}.xlsx`;
  };

  const downloadXLSFile = () => {
    return getReviewedAdditionalCompensationsXLS(page, sort, createdBetween, status, type);
  };

  const { download } = useDownloadFile({
    apiDefinition: downloadXLSFile,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });

  return (
    <TextButton disabled={buttonState} onClick={download} startIcon={<DownloadIcon />}>
      {buttonState ? 'Processing...' : 'Download XLS'}
    </TextButton>
  );
};
