import { Spinner } from '@/components/Elements';

import { useWorker } from '../api/getWorker';

import { BenefitAttachments } from './BenefitAttachments';

export const WorkerBenefitsDetails = () => {
  const workerQuery = useWorker();

  if (workerQuery.isLoading) {
    return (
      <div className="flex items-center justify-center rounded-3xl border p-4">
        <Spinner />
      </div>
    );
  }

  if (!workerQuery.data) return null;

  const worker = workerQuery.data.data;

  return (
    <>
      <div className="border-ebony-clay-200">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
          {worker.offer.benefits.statutory?.bonusDays && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-ebony-clay-500">
                Christmas bonus / aguinaldo
              </dt>
              <dd className="mt-1 text-sm text-ebony-clay-900">
                {worker.offer.benefits.statutory.bonusDays} days
              </dd>
            </div>
          )}
          {worker.offer.benefits.statutory?.vacationDays && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-ebony-clay-500">Vacation days</dt>
              <dd className="mt-1 text-sm text-ebony-clay-900">
                {worker.offer.benefits.statutory.vacationDays}
              </dd>
            </div>
          )}
          {worker.offer.benefits.statutory?.vacationsPremiumPercentage && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-ebony-clay-500">Vacation premium</dt>
              <dd className="mt-1 text-sm text-ebony-clay-900">
                {worker.offer.benefits.statutory.vacationsPremiumPercentage}%
              </dd>
            </div>
          )}
          <div className="sm:col-span-3">
            <dt className="text-sm font-medium text-ebony-clay-500">Attachments</dt>
            <dd className="mt-1 text-sm text-ebony-clay-900">
              <BenefitAttachments />
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
};
