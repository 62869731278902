import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TPaginatedQuery } from '@/types';

type AdditionalCompensations = {
  id: number;
  workerId: number;
  fullName: string;
  avatar: string;
  type: string;
  amount: string;
  currencyCode: string;
  description: string;
  status: string;
  createdAt: string;
  comment?: unknown;
  receipt?: string;
  reviewedAt?: unknown;
  reviewerName?: unknown;
};

export const getReviewedAdditionalCompensations = (
  page?: string,
  sort?: string,
  createdBetween?: string,
  status = 'accepted,rejected,canceled,processed,paid',
  type?: string
): Promise<TPaginatedQuery<AdditionalCompensations[]>> =>
  axios.get(`${API_BASE_PATH}/workers/additional-compensations`, {
    params: {
      'filter[status]': status,
      'filter[type]': type,
      'filter[createdBetween]': createdBetween,
      sort,
      page,
    },
  });

type QueryFnType = typeof getReviewedAdditionalCompensations;

type UseAdditionalCompensationsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useReviewedAdditionalCompensations = ({
  config,
}: UseAdditionalCompensationsOptions = {}) => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || undefined;
  const sort = searchParams.get('sort') || undefined;
  const createdBetween = searchParams.get('filter[createdBetween]') || undefined;
  const status = searchParams.get('filter[status]') || undefined;
  const type = searchParams.get('filter[type]') || undefined;

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['reviewed-additional-compensations', page, sort, createdBetween, status, type],
    queryFn: () => getReviewedAdditionalCompensations(page, sort, createdBetween, status, type),
  });
};
