import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { Fragment } from 'react';
import { Link } from 'react-router-dom'; //TODO check forwardRef problem with Link from Elements

import { useAuth } from '@/lib/auth';

type UserNavigationItem = {
  name: string;
  to: string;
  onClick?: () => void;
};

const MenuItems = () => {
  const userNavigation: UserNavigationItem[] = [
    {
      name: 'Settings',
      to: 'settings',
    },
    {
      name: 'Sign out',
      to: '/',
    },
  ];

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-2xl bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {userNavigation.map((item) => (
          <Menu.Item key={item.name}>
            {({ active }) => (
              <Link to={item.to} className={navLinkClass(active)} onClick={item.onClick}>
                {item.name}
              </Link>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Transition>
  );
};

export const ProfileMenu = () => {
  const { user, logout } = useAuth();

  return (
    <div className="ml-4 flex items-center">
      {/* Profile picture */}
      <span className="sr-only">Open user menu</span>
      <span className="font-medium tracking-wider">Hi, {user?.data.name}</span>
      {/* Profile dropdown */}
      <Menu as="div" className="relative ml-3">
        <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:outline-dodger-blue-600 focus:ring-2 focus:ring-dodger-blue-600 focus:ring-offset-2">
          <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
        </Menu.Button>
        <MenuItems />
      </Menu>
      <button onClick={() => logout()}>LOGOUT</button>
    </div>
  );
};

const navLinkClass = (active: boolean) =>
  clsx('block px-4 py-2 text-sm text-gray-900 focus:text-gray-500', active && 'text-gray-500');
