import { cloneElement } from 'react';

import { Filter } from '@/components/Elements/Filter';
import { SelectField, SelectFieldProps } from '@/components/Form';
import { useCountries } from '@/features/countries';

type TNativeCountrySelect = {
  omitCountries?: string[];
  options?: SelectFieldProps['options'];
};

export const FilterLocationSelect = ({ omitCountries }: TNativeCountrySelect) => {
  const countriesQuery = useCountries({
    config: {
      staleTime: Infinity,
    },
  });

  const placeholderField = <SelectField options={[]} registration={{}} />;

  if (countriesQuery.isLoading)
    return cloneElement<SelectFieldProps>(placeholderField, { disabled: true });

  if (!countriesQuery.data) return cloneElement<SelectFieldProps>(placeholderField);

  const { data: countries } = countriesQuery.data;

  const filteredCountries = countries.filter((country) => !omitCountries?.includes(country.code));
  const sortedCountries = filteredCountries.map((country) => country).sort();

  const countryOptions: SelectFieldProps['options'] = sortedCountries.map((country) => ({
    label: country.name,
    value: country.code,
  }));

  return <Filter name="location" options={[{ label: '', value: '' }, ...countryOptions]} />;
};
