import { useRoutes } from 'react-router-dom';

import { Thanks, User, VerifyLink } from '@/features/auth';
import { useAuth } from '@/lib/auth';
import { Data } from '@/types';

import { onboardingRoutes } from './onboarding';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

const createRoutes = (user?: Data<User> | null) => {
  if (user?.data.verified) {
    return protectedRoutes;
  } else if (user) {
    return onboardingRoutes;
  } else {
    return publicRoutes;
  }
};

export const AppRoutes = () => {
  const auth = useAuth();
  const commonRoutes = [
    { path: '/auth/confirmation', element: <VerifyLink /> },
    { path: '/thanks', element: <Thanks /> },
  ];

  const routes = createRoutes(auth.user);
  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
