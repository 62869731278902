import { Pagination, Spinner, Table as TableComponent, TableProps } from '@/components/Elements';
import { ROLES, useAuthorization } from '@/lib/authorization';

import { useUsers } from '../../api/getUsers';
import { UpdateUserForm } from '../UpdateUserForm';

type TUser = {
  avatar: string;
  id: number;
  name: string;
  role?: string;
  isOwner: boolean;
  email: string;
};

const tableColumns: TableProps<TUser>['columns'] = [
  {
    title: 'full name',
    field: 'name',
    Cell: ({ entry: { avatar, name } }) => (
      <div className="flex items-center gap-x-2">
        <img className="mr-1 h-8 w-8 rounded-full" src={avatar} alt="user avatar" />
        <span className="font-medium text-ebony-clay-900">{name}</span>
      </div>
    ),
  },
  {
    title: 'Email',
    field: 'email',
    isSortable: false,
  },
  {
    title: 'Role',
    field: 'role',
    isSortable: false,
    Cell: ({ entry: { role } }) => <span className="capitalize">{role}</span>,
  },
];

export const Table = () => {
  const { checkAccess } = useAuthorization();

  const usersQuery = useUsers({
    config: {
      keepPreviousData: true,
    },
  });

  if (usersQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!usersQuery.data) return null;

  const { data, links, meta } = usersQuery.data;

  const users: TUser[] = data.map((user) => ({
    avatar: user.avatar,
    name: user.name,
    id: user.id,
    role: user.role,
    email: user.email,
    isOwner: user.isOwner,
  }));

  const columns = [
    ...tableColumns,
    ...(checkAccess({ allowedRoles: [ROLES.admin] })
      ? [
          {
            title: '',
            field: 'id',
            isNarrowColumn: true,
            isSortable: false,
            Cell: ({ entry: { id, isOwner, email, name, role } }: { entry: TUser }) =>
              !isOwner ? (
                <div className="flex items-center gap-x-2">
                  <UpdateUserForm
                    defaultValues={{
                      email,
                      fullName: name,
                      role: role || '',
                    }}
                    id={id}
                  />
                </div>
              ) : null,
          },
        ]
      : []),
  ].filter(Boolean) as TableProps<TUser>['columns'];

  return (
    <TableComponent<TUser>
      data={users}
      emptyState="No users found"
      isFetching={usersQuery.isFetching && usersQuery.isPreviousData}
      columns={columns}
      pagination={
        <Pagination
          links={links}
          meta={meta}
          path="/users"
          isFetching={usersQuery.isFetching && usersQuery.isPreviousData}
        />
      }
    />
  );
};
