import currency from 'currency.js';
import { default as dayjs } from 'dayjs';

export const formatDate = (date: Date | string) => dayjs(date).format('MM/DD/YYYY');
export const formatDateByMonth = (date: Date | string) => dayjs(date).format('MMMM DD, YYYY');

const formatCurrency = (value: currency.Any, options?: currency.Options) =>
  currency(value, options);
export const formatCurrencyToString = (value: currency.Any, options?: currency.Options) =>
  formatCurrency(value, options).toString();

export const replaceHyphensWithSpaces = (value: string) => value.replace(/-/g, ' ');

export const parsedCountryCode = (currencyCode: string) => currencyCode.slice(0, -1);
