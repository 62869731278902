import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TPaginatedQuery } from '@/types';

export type TPaycycle = {
  id: number;
  status: string;
  approvedBy?: string;
  approvedDate?: string;
  failureMessage?: string;
  startDate: string;
  endDate: string;
  paymentDate: string;
  totalDue: string;
  autoApproveDate: string;
  compensations: Compensation[];
};

type Compensation = {
  id: number;
  avatar: string;
  fullName: string;
  type: string;
  team?: string;
  currencyCode: string;
  countryName: string;
  companyPays: CompanyPays;
  workersTake: WorkersTake;
};

type WorkersTake = {
  grossBasePay: string;
  bonusCommissions: string;
  workerTaxes: string;
  reimbursements: string;
  totalNetPay: string;
};

type CompanyPays = {
  totalGrossPay: string;
  reimbursements: string;
  totalEmployerTaxes: string;
  totalBenefitsAndOther: string;
  totalEmployerCost: string;
  securityDeposit: string;
  viaFee: string;
  totalDue: string;
};

export const getPaycycle = (
  page?: string,
  currencyType?: string
): Promise<TPaginatedQuery<TPaycycle>> =>
  axios.get(`${API_BASE_PATH}/paycycle`, {
    params: {
      page,
      'filter[currencyType]': currencyType,
    },
  });

type QueryFnType = typeof getPaycycle;

type UsePaycycleOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const usePaycycle = ({ config }: UsePaycycleOptions = {}) => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || undefined;
  const currencyType = searchParams.get('filter[currencyType]') || undefined;

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['paycycle', page, currencyType],
    queryFn: () => getPaycycle(page, currencyType),
  });
};
