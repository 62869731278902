import { Outlet } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';

export const Reports = () => {
  return (
    <ContentLayout title="Reports">
      <PageHeading
        title="Reports"
        description="These are some useful reports that give you an overview of your business."
      />
      <Outlet />
    </ContentLayout>
  );
};
