import { Outlet, useMatch } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';
import { TabNavigation } from '@/components/Layout/TabNavigation';
import { APP_BASE_PATH } from '@/config';

export const TimeOff = () => {
  const featureRoute = `${APP_BASE_PATH}/team/time-off`;
  const tabRoutes = [
    {
      name: 'To Review',
      to: '',
      current: Boolean(useMatch(featureRoute)),
    },
    {
      name: 'Reviewed',
      to: 'reviewed',
      current: Boolean(useMatch(`${featureRoute}/reviewed`)),
    },
  ];

  return (
    <ContentLayout title="Time Off">
      <PageHeading title="Time Off" description="Manage your team&rsquo;s time off requests" />
      <TabNavigation routes={tabRoutes} />
      <Outlet />
    </ContentLayout>
  );
};
