import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { TWorker } from '@/features/workers';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { TPaginatedQuery } from '@/types';

type InviteUserDTO = {
  email: string;
  fullName: string;
  role: string;
};

type UseInviteUserOptions = {
  config?: MutationConfig<typeof inviteUser>;
};

const inviteUser = (formData: InviteUserDTO): Promise<void> => {
  return axios.post(`${API_BASE_PATH}/users`, formData);
};

export const useInviteUser = ({ config }: UseInviteUserOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('users');

      const previousUsers = queryClient.getQueryData<TPaginatedQuery<TWorker[]>>('users');

      return { previousUsers };
    },
    onError: (_, __, context: any) => {
      if (context?.previousUsers) {
        queryClient.setQueryData('users', context.previousUsers);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      addNotification({
        type: 'success',
        title: 'Success',
      });
    },
    ...config,
    mutationFn: inviteUser,
  });
};
