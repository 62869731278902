import clsx from 'clsx';
import { ButtonHTMLAttributes, cloneElement, forwardRef } from 'react';

const variants = {
  brand: 'hover:bg-ebony-clay-100',
  primary: 'hover:bg-dodger-blue-100',
  secondary: 'hover:bg-ebony-clay-50',
  danger: 'hover:bg-froly-100',
  success: 'hover:bg-green-100',
};

const iconVariants = {
  brand: 'text-ebony-clay-800 group-hover:text-ebony-clay-900',
  primary: 'text-dodger-blue-600 group-hover:text-dodger-blue-800',
  secondary: 'text-ebony-clay-300 group-hover:text-ebony-clay-500',
  danger: 'text-froly-500 group-hover:text-froly-700',
  success: 'text-green-500 group-hover:text-green-700',
};

const sizes = {
  xs: 'px-0 py-0.5',
  sm: 'p-1',
  md: 'p-2',
  lg: 'p-3',
};

const iconSizes = {
  xs: 'h-4 w-4',
  sm: 'h-5 w-5',
  md: 'h-6 w-6',
  lg: 'h-7 w-7',
};

export type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  isLoading?: boolean;
  children: JSX.Element;
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ type = 'button', variant = 'primary', size = 'sm', children, ...props }, ref) => {
    return (
      <button
        ref={ref}
        type={type}
        className={clsx('group w-min rounded-full', variants[variant], sizes[size])}
        {...props}
      >
        {cloneElement(children, {
          className: clsx(iconVariants[variant], iconSizes[size]),
        })}
      </button>
    );
  }
);

IconButton.displayName = 'IconButton';
