import { Layout } from '@/features/onboarding';

import { RegistrationForm } from '../components/RegistrationForm';

export const Registration = () => {
  return (
    <Layout withSignOut={false}>
      <RegistrationForm />
    </Layout>
  );
};
