import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { TPaginatedQuery } from '@/types';

import { AdditionalCompensations } from './getAdditionalCompensations';

type UseProcessAdditionalCompensationOptions = {
  config?: MutationConfig<typeof processAdditionalCompensationDTO>;
};

export type ProcessAdditionalCompensationDTO = {
  status: string;
  comment?: string;
  id: number;
};

const processAdditionalCompensationDTO = ({
  id,
  ...data
}: ProcessAdditionalCompensationDTO): Promise<void> => {
  return axios.patch(`${API_BASE_PATH}/workers/additional-compensations/${id}`, data);
};

export const useProcessAdditionalCompensation = ({
  config,
}: UseProcessAdditionalCompensationOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('additional-compensations');

      const previousAdditionalCompensations = queryClient.getQueryData<
        TPaginatedQuery<AdditionalCompensations[]>
      >('additional-compensations');

      return { previousAdditionalCompensations };
    },
    onError: (_, __, context: any) => {
      if (context?.previousAdditionalCompensations) {
        queryClient.setQueryData(
          'additional-compensations',
          context.previousAdditionalCompensations
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('additional-compensations');
      addNotification({
        type: 'success',
        title: 'Success',
      });
    },
    ...config,
    mutationFn: processAdditionalCompensationDTO,
  });
};
