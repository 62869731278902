import { CheckIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { ReactNode } from 'react';

import { Button } from '@/components/Elements';
import { IconButton } from '@/components/Elements/IconButton';
import { ModalDialog } from '@/components/Elements/ModalDialog';

import { useProcessTimeOffRequest } from '../api/processTimeOffRequest';

type AcceptTimeOffProps = {
  id: number;
  fullName: string;
  startDate: string;
  endDate: string;
  requestType: string;
  description: string;
};

type InfoCardProps = {
  title: string;
  value: ReactNode;
};

const triggerButton = (
  <IconButton variant="success">
    <CheckIcon />
  </IconButton>
);

const InfoCard = ({ title, value }: InfoCardProps) => (
  <div className="col-span-1 bg-ebony-clay-50 p-4 shadow-sm">
    <dt className="text-sm font-medium text-ebony-clay-900">{title}</dt>
    <dd className="mt-1 text-sm text-ebony-clay-600">{value}</dd>
  </div>
);

export const AcceptTimeOff = ({
  id,
  fullName,
  startDate,
  endDate,
  requestType,
  description,
}: AcceptTimeOffProps) => {
  const processTimeOffRequest = useProcessTimeOffRequest();

  const handleConfirmationClick = async () => {
    await processTimeOffRequest.mutateAsync({ id, status: 'accepted' });
  };

  return (
    <ModalDialog
      icon={<InformationCircleIcon className="h-6 w-6 text-dodger-blue-600" />}
      confirmButton={
        <Button
          isLoading={processTimeOffRequest.isLoading}
          onClick={handleConfirmationClick}
          disabled={processTimeOffRequest.isLoading || processTimeOffRequest.isSuccess}
        >
          Accept
        </Button>
      }
      isDone={processTimeOffRequest.isSuccess}
      title="Accept time off request?"
      triggerButton={triggerButton}
    >
      <dl className="grid gap-2 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <InfoCard title="Worker" value={fullName} />
        </div>
        <div className="sm:col-span-1">
          <InfoCard title="Start date" value={startDate} />
        </div>
        <div className="sm:col-span-1">
          <InfoCard title="End date" value={endDate} />
        </div>
        <div className="sm:col-span-2">
          <InfoCard title="Type" value={<span className="capitalize">{requestType}</span>} />
        </div>
        <div className="sm:col-span-2">
          <InfoCard title="Description" value={description} />
        </div>
      </dl>
    </ModalDialog>
  );
};
