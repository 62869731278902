import { Link, Pagination, Pill, Spinner, Table as TableComponent } from '@/components/Elements';
import { Receipt } from '@/components/Elements/Receipt';
import { useAuth } from '@/lib/auth';
import { StatusColor } from '@/types';

import { useReviewedAdditionalCompensations } from '../../api/getReviewedAdditionalCompensations';

import { DownloadReviewedAdditionalCompensationsXLS } from './DownloadReviewedAdditionalCompensationsXLS';

type Compensation = {
  id: number;
  avatar: string;
  fullName: string;
  description: string;
  type: string;
  amount: string;
  createdAt: string;
  receipt?: string;
  status: string;
};

type TStatusColors<Color = StatusColor> = {
  accepted: Color;
  canceled: Color;
  paid: Color;
  processed: Color;
  rejected: Color;
};

const Status = ({ status }: Pick<Compensation, 'status'>) => {
  const statusTypes: TStatusColors = {
    accepted: StatusColor.Green,
    canceled: StatusColor.Gray,
    paid: StatusColor.Gray,
    processed: StatusColor.Gray,
    rejected: StatusColor.Red,
  };
  return (
    <Pill
      className="text-xs font-semibold uppercase"
      color={statusTypes[status as keyof TStatusColors]}
    >
      {status}
    </Pill>
  );
};

export const Table = () => {
  const { user } = useAuth();

  const reviewedAdditionalCompensationsQuery = useReviewedAdditionalCompensations({
    config: {
      keepPreviousData: true,
    },
  });

  if (reviewedAdditionalCompensationsQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!reviewedAdditionalCompensationsQuery.data) return null;

  const { data, links, meta } = reviewedAdditionalCompensationsQuery.data;

  const compensations: Compensation[] = data.map((compensation) => ({
    id: compensation.id,
    avatar: compensation.avatar,
    fullName: compensation.fullName,
    description: compensation.description,
    type: compensation.type,
    amount: compensation.amount,
    createdAt: compensation.createdAt,
    status: compensation.status,
    receipt: compensation.receipt,
  }));

  return (
    <TableComponent<Compensation>
      data={compensations}
      emptyState="No reimbursements found"
      isFetching={
        reviewedAdditionalCompensationsQuery.isFetching &&
        reviewedAdditionalCompensationsQuery.isPreviousData
      }
      columns={[
        {
          title: 'Status',
          field: 'status',
          Cell: ({ entry: { status } }) => <Status status={status} />,
        },
        {
          title: 'Worker',
          field: 'fullName',
          Cell: ({ entry: { avatar, fullName, id } }) => (
            <div className="flex items-center gap-x-2">
              <img className="mr-1 h-8 w-8 rounded-full" src={avatar} alt="user avatar" />
              <Link to={`/app/team/worker/${id}`}>
                <span className="font-medium text-ebony-clay-900">{fullName}</span>
              </Link>
            </div>
          ),
        },
        {
          title: 'Type',
          field: 'type',
          Cell: ({ entry: { type } }) => <p className="capitalize-first">{type}</p>,
        },
        {
          title: 'Date',
          field: 'createdAt',
        },
        {
          title: 'Amount',
          field: 'amount',
          isSortable: false,
        },
        {
          title: 'Receipt',
          field: 'receipt',
          isSortable: false,
          Cell: ({ entry: { receipt } }) => <Receipt url={receipt} />,
        },
        {
          title: 'Description',
          field: 'description',
          isSortable: false,
        },
      ]}
      downloadXLS={
        <DownloadReviewedAdditionalCompensationsXLS
          companyName={user?.data.company.name || ''}
          title="Additional Compensation"
        />
      }
      pagination={
        <Pagination
          links={links}
          meta={meta}
          path="/additional-pay/reviewed"
          isFetching={
            reviewedAdditionalCompensationsQuery.isFetching &&
            reviewedAdditionalCompensationsQuery.isPreviousData
          }
        />
      }
    />
  );
};
