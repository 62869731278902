import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';
import { OnboardContractor, OnboardEmployee, WorkersTable } from '@/features/workers';

export const TeamDashboard = () => {
  return (
    <ContentLayout title="Workers">
      <PageHeading
        description="Manage your global team"
        title="Workers 👩‍💻"
        actionComponents={
          <>
            <OnboardEmployee />
            <OnboardContractor />
          </>
        }
      />
      <WorkersTable />
    </ContentLayout>
  );
};
