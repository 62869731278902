import { ExclamationCircleIcon } from '@heroicons/react/outline';

import { Button } from '@/components/Elements';
import { ModalDialog } from '@/components/Elements/ModalDialog';
import { Authorization, ROLES } from '@/lib/authorization';

import { useApprovePaycycle } from '../api/approvePaycycle';

type ApprovePaycycleProps = {
  id: number;
  triggerButton: JSX.Element;
};

export const ApprovePaycycle = ({ id, triggerButton }: ApprovePaycycleProps) => {
  const approvePaycycle = useApprovePaycycle();

  const handleConfirmationClick = async () => {
    await approvePaycycle.mutateAsync({ id });
  };

  return (
    <Authorization allowedRoles={[ROLES.admin]}>
      <ModalDialog
        confirmButton={
          <Button
            isLoading={approvePaycycle.isLoading}
            onClick={handleConfirmationClick}
            disabled={approvePaycycle.isLoading || approvePaycycle.isSuccess}
            className="w-full sm:w-auto"
          >
            Approve
          </Button>
        }
        description="This action cannot be undone and will initiate the payroll process for the employees."
        icon={<ExclamationCircleIcon className="h-6 w-6 text-dodger-blue-600" />}
        isDone={approvePaycycle.isSuccess}
        title="Approve Paycycle?"
        triggerButton={triggerButton}
      />
    </Authorization>
  );
};
