import { ChevronDownIcon, ChevronUpIcon, MinusSmIcon } from '@heroicons/react/outline';
import { useSearchParams } from 'react-router-dom';

export const SortComponent = ({
  isSortable = true,
  columnField,
}: {
  columnField: string;
  isSortable?: boolean;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get('sort');

  const handleOnClick = (value?: string) => {
    searchParams.delete('sort');
    searchParams.delete('page');
    if (value) {
      searchParams.append('sort', value);
    }
    setSearchParams(searchParams);
  };

  if (!isSortable) return null;

  if (sort === `-${columnField}`)
    return (
      <button onClick={() => handleOnClick()}>
        <ChevronDownIcon
          className="h-4 w-4 cursor-pointer text-dodger-blue-600"
          aria-hidden="true"
        />
      </button>
    );

  if (sort === columnField)
    return (
      <button onClick={() => handleOnClick(`-${columnField}`)}>
        <ChevronUpIcon className="h-4 w-4 cursor-pointer text-dodger-blue-600" aria-hidden="true" />
      </button>
    );

  return (
    <button onClick={() => handleOnClick(`${columnField}`)}>
      <MinusSmIcon className="h-4 w-4 cursor-pointer text-dodger-blue-600" aria-hidden="true" />
    </button>
  );
};
