import { Navigate, Route, Routes } from 'react-router-dom';

import { WorkerDetails } from '../components/WorkerDetails';

import { WorkerBenefits } from './WorkerBenefits';
import { WorkerDocuments } from './WorkerDocuments';
import { WorkerProfile } from './WorkerProfile';

export const WorkerRoutes = () => (
  <Routes>
    <Route path=":workerId" element={<WorkerProfile />}>
      <Route path="" element={<WorkerDetails />} />
      <Route path="documents" element={<WorkerDocuments />} />
      <Route path="benefits" element={<WorkerBenefits />} />
      <Route path="*" element={<Navigate to="" />} />
    </Route>
  </Routes>
);

export * from './TeamDashboard';
