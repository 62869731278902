import { Button, Link } from '@/components/Elements';

import { Layout } from '../components/Layout';
import { PasswordlessLoginForm } from '../components/PasswordlessLoginForm';

export const LoginPasswordless = () => (
  <Layout title="Send email with login link">
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-3xl sm:px-10">
        <PasswordlessLoginForm />
        <div className="mt-3 flex justify-center">
          <a
            href="https://worker.via.work/"
            className="text-sm text-froly-400 hover:text-froly-300"
            target="_blank"
            rel="noreferrer"
          >
            I am an employee &rarr;
          </a>
        </div>
        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="bg-white px-2 text-gray-500">OR</span>
            </div>
          </div>

          <div className="mt-6">
            <Link to="/login" tabIndex={-1}>
              <Button variant="secondary" className="w-full">
                Login with credentials
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
