import clsx from 'clsx';
import { useMatch } from 'react-router-dom';

import { NavLink } from '@/components/Elements/NavLink';

export type SideNavigationItem = {
  name: string;
  to: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

const DesktopMenuItem = ({ item, index }: any) => {
  const match = useMatch(`${item.to}/*`);

  return (
    <NavLink
      end={index === 0}
      key={item.name}
      to={item.to}
      className={({ isActive }) => desktopNavLinkClass(isActive || Boolean(match))}
    >
      {({ isActive }) => (
        <>
          <item.icon
            className={desktopItemIconClass(isActive || Boolean(match))}
            aria-hidden="true"
          />
          {item.name}
        </>
      )}
    </NavLink>
  );
};

const MobileMenuItem = ({ item, index, onItemClick }: any) => {
  const match = useMatch(`${item.to}/*`);

  return (
    <NavLink
      end={index === 0}
      key={item.name}
      to={item.to}
      onClick={onItemClick}
      className={({ isActive }) => mobileNavLinkClass(isActive || Boolean(match))}
    >
      {({ isActive }) => (
        <>
          <item.icon
            className={mobileItemIconClass(isActive || Boolean(match))}
            aria-hidden="true"
          />
          {item.name}
        </>
      )}
    </NavLink>
  );
};

export const DesktopSideNavigation = ({ navigation }: { navigation: SideNavigationItem[] }) => {
  return (
    <nav className="flex-1 space-y-1 px-2 pb-4">
      {navigation.map((item, index) => (
        <DesktopMenuItem key={item.to + index} item={item} index={index} />
      ))}
    </nav>
  );
};

export const MobileSideNavigation = ({
  navigation,
  onItemClick,
}: {
  navigation: SideNavigationItem[];
  onItemClick: React.MouseEventHandler<HTMLAnchorElement>;
}) => (
  <nav className="my-1 space-y-1 px-2">
    {navigation.map((item, index) => (
      <MobileMenuItem key={item.to + index} item={item} index={index} onItemClick={onItemClick} />
    ))}
  </nav>
);

const desktopNavLinkClass = (isActive: boolean): string =>
  clsx(
    isActive
      ? 'bg-ebony-clay-100 text-ebony-clay-900'
      : 'text-ebony-clay-600 hover:bg-ebony-clay-50 hover:text-ebony-clay-900',
    'group flex items-center rounded-full px-4 py-2 text-sm font-medium focus:outline-black'
  );

const desktopItemIconClass = (isActive: boolean): string =>
  clsx(
    isActive ? 'text-ebony-clay-600' : 'text-ebony-clay-500 group-hover:text-ebony-clay-600',
    'mr-3 h-6 w-6 flex-shrink-0'
  );

const mobileNavLinkClass = (isActive: boolean): string =>
  clsx(
    isActive
      ? 'bg-ebony-clay-100 text-ebony-clay-900'
      : 'text-ebony-clay-600 hover:bg-ebony-clay-50 hover:text-ebony-clay-900',
    'group flex items-center rounded-full px-2 py-2 text-base font-medium focus:outline-black'
  );

const mobileItemIconClass = (isActive: boolean): string =>
  clsx(
    isActive ? 'text-ebony-clay-600' : 'text-ebony-clay-500 group-hover:text-ebony-clay-600',
    'mr-4 h-6 w-6 flex-shrink-0'
  );
