import { Layout } from '../components/Layout';
import { UpdateCompanyForm } from '../components/UpdateCompanyForm';

export const Company = () => {
  return (
    <Layout>
      <UpdateCompanyForm />
    </Layout>
  );
};
